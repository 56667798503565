<template>
  <div style="height: 100%">
    <!-- :style="{ 'background-color': pageData.style.backgroundColor }" -->
    <div
      class="center-box"
      :style="{ height: `calc(100% - ${paddingBottom})` }"
    >
      <transition-group type="transition">
        <div v-for="(item, index) in pageData.widgets" :key="'data' + index">
          <widget
            :id="'data' + index"
            :w-data="item"
            :opt="opt"
            :class="index == opt.currentWidget ? 'widget-choosed' : ''"
            :style="
              item.type == 'bottom-wx' ||
              item.type == 'wx' ||
              item.type == 'wxImgs' ||
              item.type == 'gzhImgs' ||
              item.type == 'long-copy' ||
              item.type == 'click-copy'
                ? 'transform: none;position:unset;'
                : ''
            "
            :data-clipboard-text="item.props && item.props.wechatNumber"
            @copyBtn="copyBtn(index)"
          />
        </div>
      </transition-group>
    </div>
    <div v-if="getBottom()" @click="widgetClick(bottomBox, bottomIndex)">
      <wxVCard
        id="dataBottom"
        ref="bottomBox"
        style="width: 100%; transform: none"
        :class="opt.currentWidget == bottomIndex ? 'widget-choosed' : ''"
        :data-clipboard-text="bottomBox.props && bottomBox.props.wechatNumber"
        :w-data="bottomBox"
        :opt="opt"
        @copyBtn="copyBtn('Bottom')"
      ></wxVCard>
    </div>
  </div>
</template>

<script>
import widget from "./widget.vue";
import wxVCard from "./template/wxVCard.vue";
import clipboard from "clipboard";
let copyFct = null;
export default {
  components: {
    widget,
    wxVCard,
  },
  props: {
    opt: {
      type: Object,
      default() {
        return {
          isEditPreview: false,
          currentPage: 0,
          currentWidget: 0,
        };
      },
    },
    pageData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          widgets: [],
          style: {
            backgroundColor: "",
          },
          script: {},
        };
      },
    },
  },
  data() {
    return {
      bottomBox: {},
      paddingBottom: "0px",
      bottomIndex: -1,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 400,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    bottomBox() {
      this.$nextTick(() => {
        this.getBottom();
        if (this.getBottom()) {
          this.paddingBottom = `${this.$refs.bottomBox.$el.clientHeight}px`;
        }
      });
    },
  },
  methods: {
    getBottom() {
      let index = this.pageData.widgets.findIndex(
        (i) => i.type == "bottom-wx" || i.type == "bottom-long-wx"
      );
      if (index != -1) {
        this.bottomBox = this.pageData.widgets[index];
        this.bottomIndex = index;
        return true;
      } else {
        if (this.bottomIndex != -1) {
          this.bottomBox = {};
          this.bottomIndex = -1;
          this.paddingBottom = "0px";
        }
        return false;
      }
    },
    copyBtn(index) {
      if (!copyFct) {
        copyFct = new clipboard("#data" + index);
      } else {
        copyFct.destroy();
        copyFct = new clipboard("#data" + index);
      }
      copyFct.on("success", function () {
        console.log("复制成功");
      });
    },
  },
};
</script>

<style scoped>
.center-box {
  overflow-x: hidden;
}
.widget-choosed {
  position: relative;
  z-index: 1;
  box-shadow: 1px 1px 8px #000;
  box-sizing: border-box;
}
.bottom-fixd {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 3;
  width: 100%;
  max-width: 500px;
  bottom: 0;
}
</style>
