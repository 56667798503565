<template>
  <div id="page-content">
    <div class="page-box">
      <advertiseTemplate
        :opt="opt"
        :page-data="siteData.pages[opt.currentPage]"
      />
    </div>
  </div>
</template>

<script>
import advertiseTemplate from "@/components/advertiseTemplate.vue";
export default {
  components: {
    advertiseTemplate,
  },
  data() {
    return {
      landingPageId: "",
      templateId: "",
      siteData: {
        templateId: "",
        mainPage: 0,
        pages: [
          {
            id: "page1",
            name: "页面1",
            widgets: [
              {
                id: "id1",
                type: "wx",
                props: {
                  assemblyBackgroundColor: "#ffffff",
                  assemblyFontColor: "",
                  assemblyHeight: "",
                  assemblyFont: "14px",
                  followButtonBackgroundColor: "#13ce66",
                  followButtonFontColor: "#ffffff",
                  followButtonFont: "14px",
                  followButtonContent: "关注",
                  followButtonFillet: 20,
                  followButtonHeight: "25",
                  followButtonWidth: "50",
                  wechatNumber: "weixin帐号",
                  wechatNumberFont: "",
                  wechatNumberColor: "",
                  wechatPictureUrl:
                    "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
                  wechatPictureHeight: 20,
                  wechatPictureWidth: 20,
                  popupType: "",
                  finger: "",
                },
              },
            ],
          },
          {
            id: "page2",
            name: "页面2",
            widgets: [
              {
                id: "id3",
                type: "issue",
                props: {
                  backgroundColor: "",
                  questionFont: "14px",
                  questionFontColor: "",
                  questionFontFillColor: "",
                  questionFillet: 20,
                  optionFont: "14px",
                  optionFontColor: "",
                  optionFontFillColor: "",
                  optionFillet: 20,
                  buttonContent: "按钮",
                  questions: [
                    {
                      question: "标题",
                      options: ["选项1", "选项2"],
                      sort: 1,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      opt: {
        isEditPreview: true,
        currentPage: 0,
        currentWidget: 0,
        channelCode: "",
        landinCode: "",
        isOfficial: true,
      },
    };
  },
  mounted() {
    this.templateId = this.$route.query.id;
    this.getTemplateInfo();
    this.getPageInfo();
    if (this.templateId) {
      this.addLandingPagePV();
    }
  },
  methods: {
    getTemplateInfo() {
      this.$http
        .get(
          "/function/component/getTemplateById?templateId=" + this.templateId
        )
        .then((response) => {
          if (response.data.code === 200) {
            let tempInfo = JSON.parse(JSON.stringify(response.data.data));
            this.siteData.pages = this.siteData.pages.map((v, i) => {
              v.widgets = tempInfo.reduce((prev, val) => {
                if (val.level == i + 1) {
                  if (val.type == 7) {
                    val.data.questions = val.data.questions.map((value) => {
                      value.options = value.options.split(",");
                      return value;
                    });
                  }
                  prev.push({
                    type: val.typeName,
                    props: val.data,
                  });
                }
                return prev;
              }, []);
              return v;
            });
          }
        });
    },
    getPageInfo() {
      this.$http
        .get(
          "/system/template/getWeChatInfoByLandingPageId?landingPageId=" + 30
        )
        .then((res) => {});
    },
    setWechat() {},
    addLandingPagePV() {
      let params = {};
      this.$http
        .post("/system/statistics/addLandingPagePV", params)
        .then((res) => {});
    },
    addToWeChatCA() {
      let params = {};
      this.$http
        .post("/system/statistics/addToWeChatCA", params)
        .then((res) => {});
    },
    addToWeChatPV() {
      let params = {};
      this.$http
        .post("/system/statistics/addToWeChatPV", params)
        .then((res) => {});
    },
  },
};
</script>

<style scoped>
#page-content {
  width: 100%;
  height: 100%;
  background: linear-gradient(rgb(92, 165, 210), #9198e5);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}
.page-box {
  position: relative;
  max-width: 500px;
  min-height: 100%;
  margin: 0 auto !important;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.page-box /deep/.center-box {
  overflow: hidden;
}
</style>
